import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Container from 'components/Container'
import Subscribe from 'components/Forms/Subscribe'

const filter1 = `<iframe src="https://codesandbox.io/embed/xenodochial-heyrovsky-hbt0d?autoresize=1&expanddevtools=1&fontsize=14&hidenavigation=1&module=%2Fsrc%2Findex.js&view=editor" title="xenodochial-heyrovsky-hbt0d" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media; usb" style="width:100%; height:500px; border:0; border-radius: 4px; overflow:hidden;" sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>`
const map1 = `<iframe src="https://codesandbox.io/embed/twilight-microservice-r90x2?autoresize=1&fontsize=14&hidenavigation=1&module=%2Fsrc%2Findex.js&view=editor" title="twilight-microservice-r90x2" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media; usb" style="width:100%; height:500px; border:0; border-radius: 4px; overflow:hidden;" sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>`
const reduce1 = `<iframe src="https://codesandbox.io/embed/happy-albattani-fk86m?autoresize=1&fontsize=14&hidenavigation=1&module=%2Fsrc%2Findex.js&view=editor" title="happy-albattani-fk86m" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media; usb" style="width:100%; height:500px; border:0; border-radius: 4px; overflow:hidden;" sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>`
const all_together = `<iframe src="https://codesandbox.io/embed/exciting-bardeen-yuvml?autoresize=1&fontsize=14&hidenavigation=1&module=%2Fsrc%2Findex.js&view=editor" title="exciting-bardeen-yuvml" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media; usb" style="width:100%; height:500px; border:0; border-radius: 4px; overflow:hidden;" sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>`
const chain = `<iframe src="https://codesandbox.io/embed/amazing-shadow-yic25?autoresize=1&fontsize=14&hidenavigation=1&module=%2Fsrc%2Findex.js&view=editor" title="amazing-shadow-yic25" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media; usb" style="width:100%; height:500px; border:0; border-radius: 4px; overflow:hidden;" sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>`
const reuse = `<iframe src="https://codesandbox.io/embed/lively-wildflower-g3e3h?autoresize=1&fontsize=14&hidenavigation=1&view=editor" title="lively-wildflower-g3e3h" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media; usb" style="width:100%; height:500px; border:0; border-radius: 4px; overflow:hidden;" sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>`

export default ({ data: { site } }) => (
  <Layout site={site} noFooter>
    <Helmet>
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@chrisachard" />
      <meta name="twitter:title" content="Map, Filter, Reduce" />
      <meta name="twitter:description" content="Code examples for map/filter/reduce crash course" />
      <meta name="twitter:image" content="https://chrisachard.com/images/crash-courses/map-filter-reduce.png" />
    </Helmet>
    <Container>
      <h1>Map, Filter, Reduce Code Samples</h1>

      <p>These examples are from my map/filter/reduce crash course twitter: <a href="https://twitter.com/chrisachard/status/1173750491458789378">https://twitter.com/chrisachard/status/1173750491458789378</a></p>

      <h3>Filter</h3>
      <span dangerouslySetInnerHTML={{ __html: filter1 }} />
      
      <h3>Map</h3>
      <span dangerouslySetInnerHTML={{ __html: map1 }} />
      
      <h3>Reduce</h3>
      <span dangerouslySetInnerHTML={{ __html: reduce1 }} />

      <h3>Extra Arguments</h3>
      <span dangerouslySetInnerHTML={{ __html: all_together }} />

      <h3>Chain Together</h3>
      <span dangerouslySetInnerHTML={{ __html: chain }} />

      <h3>Reuse Functions</h3>
      <span dangerouslySetInnerHTML={{ __html: reuse }} />

      <br />
      <Subscribe />

    </Container>
  </Layout>
)

export const pageQuery = graphql`
  query {
    site {
      ...site
    }
  }
`
